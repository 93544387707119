import(/* webpackMode: "eager", webpackExports: ["PrimaryCtaLink"] */ "/vercel/path0/apps/PublicUI/app/components/MarketplaceShell/Header/PrimaryCtaLink/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/PublicUI/app/components/MarketplaceShell/Header/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/PublicUI/app/(marketplace_headerless)/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["UserDropdown"] */ "/vercel/path0/apps/PublicUI/app/components/MarketplaceShell/Header/UserDropdown/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MarketplaceAppContext"] */ "/vercel/path0/apps/PublicUI/contexts/AppContext/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/PublicUI/pages_components/home/SearchBar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["IAmClientProvider"] */ "/vercel/path0/apps/PublicUI/services/IAm/provider/clientProvider/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
